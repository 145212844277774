import firebase from './firebase';
import 'firebase/auth';
import 'firebase/database';
// import { FirebaseAuth } from 'showme-client/src/auth';
import { AUTH_STATE } from '../actions/action-types';
import { createAction } from '../actions/flux-standard-actions';
import { Dispatch, Reducer, useReducer } from 'react';
import { User } from '../store/User';
import { extractAuthToken, removeAuthToken } from './token-parser';
import { parseJwt } from './parse-jwt';

export const googleScope = 'openid profile email https://www.googleapis.com/auth/dialogflow';

export interface AuthAction {
    type: string; // 'AUTH_STATE';
    payload?: User;
}
interface AuthState {
    user?: User;
}

const ANONYMOUS: User = {isAnonymous: true};

export function useAuthService(reducer: Reducer<AuthState, AuthAction>, initialState: AuthState): [AuthState, Dispatch<AuthAction>] {
    const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>>(reducer, {...initialState, user: authService.user});
    authService.setDispatch(dispatch);

    return [state, dispatch];
}

class AuthService { // extends FirebaseAuth {
    public user: User;
    private pendingUid: string;
    private dispatch: Dispatch<AuthAction>;
    private accessToken: string;
    private refreshToken: string;
    // private unsubscribe: Function;

    constructor() {
        // bind to this so it can be used as an event handler
        this.signOut = this.signOut.bind(this);
        this.beforeSignIn = this.beforeSignIn.bind(this);
        // console.info('currentUser:', firebase.auth().currentUser);

        // initialise AuthService with auth token response or from local storage
        const { user, access_token, refresh_token, expires_in } = extractAuthToken();

        if (user) {
            this.user = user;
        } else {
            this.user = ANONYMOUS;

            const auth = firebase.auth();
            auth.signInAnonymously().catch(err => {
                console.error('Failed to sign in to Firebase anonymously', err);
            });
        }

        if (access_token) {
            this.onAccessToken(access_token, refresh_token, expires_in);
        }
    }

    setDispatch(dispatch: Dispatch<AuthAction>) {
        // console.info('...........AuthService.setDispatch', !this.dispatch ? 'first time' : dispatch === this.dispatch ? 'same' : 'different');
        if (dispatch === this.dispatch) {
            // console.info('!!!!!!!!!!!!!!already have dispatch');
            return;
        }
        this.dispatch = dispatch;

        // if (!this.unsubscribe) {
        //     this.unsubscribe =
        const self = this;
        firebase.auth().onAuthStateChanged(function(user: firebase.User) {
            // console.info('AuthService has firebase user:', user && user.uid);
            // TODO: do more testing around how auth state can change, and how to update/replace the user object
            if (user) {
                if (self.pendingUid && user.uid !== self.pendingUid) {
                    return;
                }
                if (!self.user || user.uid !== self.user.id || user.isAnonymous !== !!self.user.isAnonymous) {
                    // if (user.uid === self.user.id) {
                    //     console.info('uid is actually the same');
                    //     return;
                    // }
                    self.user = {...self.user, id: user.uid, isAnonymous: user.isAnonymous};
                    // self.user.id = user.uid;
                    // self.user.isAnonymous = user.isAnonymous;
                }
            } else {
                self.user = ANONYMOUS;
                firebase.auth().signInAnonymously();
            }
            self.dispatch(createAction(AUTH_STATE, self.user));
        });
        // }
    }

    async onAccessToken(token: string, refreshToken: string, expiresInSeconds?: number) {
        // console.debug('onAccessToken:', token);
        const claims = parseJwt(token);
        if (!expiresInSeconds) {
            expiresInSeconds = claims.exp - Date.now() / 1000;
        }

        this.setAccessToken(token, refreshToken, expiresInSeconds);
        this.accessToken = token;
        this.refreshToken = refreshToken;

        const auth = firebase.auth();
        try {
            this.pendingUid = claims.uid;
            await auth.signInWithCustomToken(token);
        } catch (err) {
            console.error('Failed to sign in with token:', err);
        }
    }

    beforeSignIn(e: React.MouseEvent, state: string) {
        // console.info('before sign on should set loading=true', state);
        this.dispatch(createAction(AUTH_STATE, {...this.user, loading: true}));
        // Because Firebase won't let us link an anonymous account with an an account from our custom OAuth provider
        // we do the account linking in the back-end
        state += '&uid=' + this.user.id;
        firebase.database().ref(`users/${this.user.id}/auth-state`).set(state);
        return state;
    }

    signOut() {
        removeAuthToken();
        firebase.auth().signOut();
        this.revokeRefreshToken();
        // firebase.auth().updateCurrentUser(null);
        console.info('dispatching null AUTH_STATE');
        this.dispatch(createAction(AUTH_STATE, ANONYMOUS));
    }

    async refreshAccessToken(accessToken: string, refreshToken: string) {
        try {
            console.info('refreshing access token');
            const response = await fetch('https://dubbiebee.com/api/oauth/token', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken
                })
            });

            const tokens = await response.json();
            this.setAccessToken(tokens.access_token, tokens.refresh_token, tokens.expires_in);
        } catch (err) {
            console.info('failed to refresh access token');
            this.signOut();
        }
    }

    private async setAccessToken(token: string, refreshToken: string, expiresInSeconds: number) {
        this.accessToken = token;
        this.refreshToken = refreshToken;

        localStorage.setItem('access_token', token);
        localStorage.setItem('refresh_token', refreshToken);

        if (expiresInSeconds <= 0) {
            await this.refreshAccessToken(token, refreshToken);
        } else {
            setTimeout(() => {
                this.refreshAccessToken(token, refreshToken);
            }, expiresInSeconds * 1000);
        }
    }

    private revokeRefreshToken() {
        if (this.refreshToken) {
            localStorage.removeItem('refresh_token');

            fetch('https://dubbiebee.com/api/oauth/token/revoke', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    refresh_token: this.refreshToken
                })
            });
        }
    }
}

/*class DubbieBeeCredential extends firebase.auth.AuthCredential {
    constructor() {
        super();
        this.providerId = 'dubbiebee';
        // this.pendingToken_ = oauthResponse['pendingToken'];
    }

    linkToIdToken(rpcHandler: any /!*firebase.auth.RpcHandler*!/, idToken: string) {
        debugger;


        /!*
        Firebase impl:
        var request = this.makeVerifyAssertionRequest_();
        request['idToken'] = idToken;
        return rpcHandler.verifyAssertionForLinking(
            /!** @type {!fireauth.RpcHandler.VerifyAssertionData} *!/ (request));*!/
    }
}*/

/*
// @ts-ignore
firebase.auth.OAuthCredential.prototype.makeVerifyAssertionRequest_ = function() {
    debugger;
    return {
        // 'pendingToken': this.pendingToken_,
        // Always use http://localhost.
        'postBody': JSON.stringify({
            id_token: '',
            access_token: '',
            oauth_token_secret: '',
            providerId: '',
            nonce: '',
        }),
        'requestUri': 'https://dubbiebee.com/api/oauth/token'
    };
};
*/
// console.debug('create private AuthService singleton');
const authService = new AuthService();

export function getAuthService() {
    return authService;
}
