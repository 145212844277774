// export const CHILD_ADDED = 'CHILD_ADDED';
// export const STATUS = 'STATUS';
// export const ERROR = 'ERROR';

// export const IGNORE_JOB = 'IGNORE_JOB';


/** Payload contains {prompt: string | JSX.Element, form?: JSX.Element} */
export const INITIAL_PROMPT = 'INITIAL_PROMPT';

/**
 * User has responded to the bot, by text or speech.
 * We need to force the prompt to update (otherwise if we let the WebBot update the state.prompt to '...' internally
 * repeating a prompt ("sorry, I don't understand") is ignored by React.
 * We also want to give the front-end code a chance to update.
 */
export const USER_RESPONSE = 'USER_RESPONSE';

/** payload will contain NLPResponse with updated slots, context and next prompt and/or action */
export const NLP_RESPONSE = 'NLP_RESPONSE';

/**
 * payload will be null if logged out or will contain:
 *  {uid: string, displayName: string, email: string, photoURL: string, providerId: string}
 */
export const AUTH_STATE = 'AUTH_STATE';
export const COARSE_LOCATION = 'COARSE_LOCATION';

/**
 * This action type will be dispatched when the browser history receives a location change.
 */
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
