import * as React from 'react';
import * as CSS from 'csstype';
import GoogleSignIn from '../components/auth/GoogleSignIn';
import { getAuthService, googleScope } from '../services/AuthService';
import VoiceLink from 'react-web-bot/src/components/VoiceLink';

const nameStyle = {
    fontSize: '58px',
    display: 'block',
    textAlign: 'center' as CSS.TextAlignProperty,
    marginBottom: '-12px'
};

export const welcomePrompt = (
    <div>
    <p>Hi, my name is
        <span
            className="dubbie-bee-font"
            style={nameStyle}
        >
            dubbie bee
        </span>
    </p>
    <p>Have we met?<br/>Sign in so that I can personalise your experience.</p>
    </div>
);

export const signInForm = (
<div className="vertical">
    <q>Sign in with <GoogleSignIn dark scope={googleScope} onClick={getAuthService().beforeSignIn} /></q>
    {/*<q><Link to="/account/linking">Link another account</Link></q>*/}
</div>
);

//noinspection TsLint
export const welcomeForm = (
<div>
    <div className="vertical">
        {signInForm.props.children}
        <VoiceLink href="/start">Maybe later</VoiceLink>
    </div>

    <div className="notes">
        <small role="note">When you see quotes like these <q></q> you can say this to me.</small>
        <small role="note">If you see a link or a button you can tap on it</small>
    </div>
</div>
);
