import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * @param children eg `<p>Loading...</p>`
 * @constructor
 */
const Loading = ({children}: {children?: React.ReactNode}) => (
    <div
        style={{
            position: 'absolute',
            top: 0, right: 0, bottom: 0, left: 0,
            display: 'flex'}}
        aria-busy="true"
        aria-live="assertive"
        role="alert"
    >
        {children}
        <CircularProgress color="secondary" style={{margin: 'auto'}}/>
    </div>
);

export default Loading;
