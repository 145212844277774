import * as React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../store/User';

// TODO:  get anonymous user from back-end (custom or Firebase) and provide to Lex via Redux state<br/>
// I suppose Lex has Cognito, and custom NLP back-end can generate missing anonymous user.
//     Probably best to send requests to Lex/Api.AI via custom backend
//
export function startPrompt(user: User, previousPath?: string) {
    console.info('...........previousPath:', previousPath);
    return previousPath && previousPath !== '/start' ? (
        <div>
            <p style={{textAlign: 'center'}}>
                Sure, you can ask for any of those options <br/>
                or <q><Link to="/help">help</Link></q> at any time.</p>
            <p>Now, what can I do for you?</p>
        </div>
        ) : (
        <p>{user.firstName ? ('Hi ' + user.firstName) : 'Hi'}, what can I do for you?</p>
        );
}
