// Based on https://github.com/acdlite/flux-standard-action
// import { Dispatch, Action } from 'redux';

import { Dispatch } from 'react';

export interface FluxStandardAction {
    type: string;
    payload?: any;
    error?: boolean;
    meta?: any;
}

export interface FluxStandardError {
    type: string;
    payload: Error;
    error?: true;
    meta?: any;
}

// export const dispatchAction = (type: string, payload?: any) => dispatch(createAction(type, payload));

// Promise.prototype.thenAction = (resolve, reject) => {
// };

// somethingWithPromise(params).then(createAction.bind(null, 'SOMETHING), createError.bind(null, 'SOMETHING));
export function createAction(type: string, payload?: any): FluxStandardAction {
    return { type, payload };
}

export function createError(type: string, error: Error): FluxStandardError {
    return { type, error: true, payload: error };
}

// somethingAsync(params, actionOnCallback.bind(null, 'SOMETHING'))
export function actionOnCallback(type: string, error?: Error, payload?: any) {
    return error ? createError(type, error) : createAction(type, payload);
}

export function onActionCallback(dispatch: Dispatch<any>, action: string) {
    return (err?: Error, payload?: any) => {
        dispatch(actionOnCallback(action, err, payload));
    };
}
