export const firebaseConfig = {
    apiKey: 'AIzaSyB4lEEXkZIQKdzcIOZkF3aLelww6_JP9l8',
    authDomain: 'dubbie-bee.firebaseapp.com',
    databaseURL: 'https://dubbie-bee.firebaseio.com',
    projectId: 'dubbie-bee',
    storageBucket: 'dubbie-bee.appspot.com',
    messagingSenderId: '258978911759',
    appId: '1:258978911759:web:2fd8171e2915d37c'
};

export const googleMapsApiKey = 'AIzaSyB4lEEXkZIQKdzcIOZkF3aLelww6_JP9l8';

// export const googleClientId = '258978911759-3t2cq2g3cne9gtv54luo5j5f89fhj4kl.apps.googleusercontent.com';

// Config for react-redux-firebase
// For more details, visit https://prescottprue.gitbooks.io/react-redux-firebase/content/config.html
/*export const reduxFirebase = {
    userProfile: 'users', // root that user profiles are written to
    enableLogging: false, // enable/disable Firebase Database Logging
    updateProfileOnLogin: false // enable/disable updating of profile on login
    // profileDecorator: (userData) => ({ email: userData.email }) // customize format of user profile
};*/

// export const env = 'development';

// export default { firebase, reduxFirebase, env };
