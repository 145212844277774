import * as React from 'react';
import { AppContext } from '../containers/App';
import { useContext } from 'react';
// import { Redirect } from 'react-router';
import { RouteComponentProps } from 'react-router';
// import { startLogin } from 'showme-client/src/auth';
// import { welcomePrompt, welcomeForm } from '../prompts/welcome-prompt';

// const mapDispatchToProps = (dispatch: Dispatch<any>, props?: any) => {
//     console.info('Home mapDispatchToProps:', props);
//
//     return {
//         onEnter: dispatch(createAction(INITIAL_PROMPT, {
//             prompt: welcomePrompt,
//             form: welcomeForm
//         }))
//     }
// };

export default function Home({history}: RouteComponentProps<any>) {
    const app = useContext(AppContext);

    history.replace('/jobs');
    if (app.user && !app.user.isAnonymous) {
        history.replace('/start');
    }

    return ( // app.user && !app.user.isAnonymous) ? <Redirect to="/start" /> : (
        <div>
            {/*<p>Welcome to dubbie bee</p>*/}
            {/*<Redirect to="/jobs" />*/}
            {/*<Redirect to={{pathname: '/jobs', state: { prompt: 'Welcome to dubbie bee' }}} />*/}
            {/*<button onClick={login}>Log in</button>*/}
        </div>
    );
}
