import * as React from 'react';
// import { RouteComponentProps /*, withRouter*/} from 'react-router';
// import { startPrompt } from '../prompts/start-prompt';
// import { Agent } from '../store/state';
// import VoiceLink from 'react-web-bot/src/components/VoiceLink';
// import { generateSampleUtterances } from '../services/agent-service';
// import { useWebBotPrompt } from '../hooks/useWebBot';
// import { useEffect } from 'react';
// import {User} from '../store/User';
// import {startLogin} from 'showme-client/src/auth';


export default function Start(props: any) {
    console.info('Start page', props);

    // const [bot, displayPrompt] = useWebBotPrompt();
    // const displayPrompt = useWebBotPrompt()[1];
    // const user = {};
    // const router = {previous: {pathname: 'foo'}};
    // const prompt = startPrompt(user, router.previous && router.previous.pathname);
    //
    // useEffect(() => {
    //     displayPrompt(prompt
    //         // form: this.props.form
    //         /*prompt: startPrompt(), // this.state.router.previous && state.router.previous.pathname),*/
    //         // generateSampleUtterances(bot.agents as Agent[]).map((item, i) => {
    //         //     return (
    //         //         <VoiceLink key={i} href={item.href}>
    //         //             {item.text}
    //         //         </VoiceLink>
    //         //     );
    //         // })
    //     );
    // }, []);

    return (
        <div>
            {/*<h1>Start</h1>*/}

            {/*<button onClick={login}>Log in</button>*/}
        </div>
    );
}
