import * as React from 'react';
import { Suspense, lazy } from 'react';
import { Route } from 'react-router';
import { History } from 'history';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Slide } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { darkBrown } from '../theme';
import GoogleSignIn from '../components/auth/GoogleSignIn';
// import LinkedInSignIn from '../components/auth/LinkedInSignIn';
import { getAuthService, googleScope } from '../services/AuthService';
import { User } from '../store/User';

const JobsNav = lazy(() => import(/* webpackChunkName: "jobs" */ '../routes/jobs/JobsNav'));
const BotIcon = require('../images/app-icon-32.png');

export interface AppNavProps { // extends RouteComponentProps<void> {
    history: History;
    user?: User;
    // authService: AuthService;
    // drawerOpen?: boolean;
    // imageUrl?: string;
}

export interface AppNavState {
    drawerOpen?: boolean;
}

function HideOnScroll({children}: {children: React.ReactElement}) {
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function AppNav({history, user}: AppNavProps) {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const authService = getAuthService();
    // const [drawOpen, setDrawerOpen] = useState(false);
    // toggleDrawer = () => setDrawerOpen(!drawerOpen);
    // closeDrawer = () => setDrawerOpen(false);

    // useEffect(() => {
    // //     // console.info('keep tsc happy:', location, LOCATION_CHANGE, createAction, history);
    //     return history.listen((location: Location) => {
    //         // console.info('AppNav: location changed:', location, LOCATION_CHANGE, createAction);
    //         props.dispatch(createAction(LOCATION_CHANGE, location));
    //     });
    // }, []);

    // console.info('AppNav user:', user);
    let userIcon;
    if (user) {
        if (user.photoURL) {
            userIcon = <img src={user.photoURL}/>; // title={user.name}/>;
        } else if (user.loading) {
            userIcon = <CircularProgress color="secondary"/>;
        }
    }
    if (!userIcon) {
        userIcon = (
            <svg viewBox="0 0 24 24" height="36" width="36">
                <path
                    d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                    fill={darkBrown}
                />
            </svg>
        );
    }

    function onClickUserIcon(event: React.MouseEvent<HTMLElement>) {
        setMenuAnchor(event.currentTarget);
    }

    function signOut() {
        closeMenu();
        authService!.signOut();
        history.replace('/');
    }

    function closeMenu() {
        setMenuAnchor(null);
    }

    function onClickBotIcon() {
        console.info('TODO: onClickBotIcon');
    }

    return (
        <React.Fragment>
            <HideOnScroll>
                <AppBar>
                    <Toolbar>
                        {/*<Container>*/}
                        {/*<IconButton onClick={this.toggleDrawer}>
                            <MenuIcon/>
                        </IconButton>*/}
                        <IconButton className="bot-icon" aria-label="dubbie bee" onClick={onClickBotIcon}>
                            <img src={BotIcon} alt=""/>
                        </IconButton>

                        <h1 onClick={() => history.push(user && !user.isAnonymous ? '/start' : '/')}>
                            dubbie bee
                        </h1>

                        <Suspense fallback={<span>...</span>}>
                            <Route
                                path="/jobs"
                                exact
                                component={JobsNav}
                            />
                            <Route
                                path="/jobs/search-profile/:profileName?"
                                component={JobsNav}
                            />
                        </Suspense>

                        <span className="grow" />
                        <IconButton className="user-icon" onClick={onClickUserIcon}>
                            {userIcon}
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchor}
                            keepMounted
                            open={!!menuAnchor}
                            onClose={closeMenu}
                        >
                            {!(user && user.googleId) && <MenuItem onClick={closeMenu}>
                                <GoogleSignIn dark scope={googleScope} onClick={authService.beforeSignIn} label="Sign in with Google"/>
                            </MenuItem>}
                            {/*{!(user && user.linkedAccounts) && <MenuItem onClick={closeMenu}>
                                <LinkedInSignIn onClick={authService.beforeSignIn}/>
                            </MenuItem>}*/}

                            {user && !user.isAnonymous && <MenuItem onClick={signOut}>Log out</MenuItem>}
                            {/*<MenuItem>
                                <Link to="account/linking">Linked accounts</Link>
                            </MenuItem>*/}
                        </Menu>
                        {/*</Container>*/}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
            {/*<Drawer*/}
            {/*    variant="temporary"*/}
            {/*    open={drawerOpen}*/}
            {/*    onClose={open => {*/}
            {/*        console.info('change:', open);*/}
            {/*        // this.setState({drawerOpen: open});*/}
            {/*    }}*/}
            {/*>*/}
            {/*    /!*<MenuItem onClick={this.closeDrawer}>About <span className="dubbie-bee-font">dubbie bee</span></MenuItem>*!/*/}
            {/*    /!*<MenuItem onClick={this.closeDrawer}>Contact us</MenuItem>*!/*/}
            {/*</Drawer>*/}
        </React.Fragment>
    );
}

// AppNav.whyDidYouRender = true;
// AppNav.whyDidYouRender = {
//     logOnDifferentValues: true,
//     collapseGroups: true
// };
export default AppNav;