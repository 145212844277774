// tslint:disable-next-line:max-line-length
// https://netmarketshare.com/browser-market-share.aspx?options=%7B%22filter%22%3A%7B%22%24and%22%3A%5B%7B%22deviceType%22%3A%7B%22%24in%22%3A%5B%22Desktop%2Flaptop%22%5D%7D%7D%5D%7D%2C%22dateLabel%22%3A%22Trend%22%2C%22attributes%22%3A%22share%22%2C%22group%22%3A%22browserVersion%22%2C%22sort%22%3A%7B%22share%22%3A-1%7D%2C%22id%22%3A%22browsersDesktopVersions%22%2C%22dateInterval%22%3A%22Monthly%22%2C%22dateStart%22%3A%222018-07%22%2C%22dateEnd%22%3A%222019-06%22%2C%22segments%22%3A%22-1000%22%2C%22pageLength%22%3A50%7D
// using cdn.polyfill.io in index.html
// import 'react-app-polyfill/ie11';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

if (process.env.NODE_ENV !== 'production') {
    // const whyDidYouRender = require('@welldone-software/why-did-you-render');
    const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
    whyDidYouRender(React);
}

import App from './containers/App';
import './index.scss';

// const render = () => {
ReactDOM.render(<App/>, document.getElementById('root'));
// };

// we defer rendering the application until we've fetched the user
// from local storage and potentially updated their local state
// performLogin(state.cognito.user, state.cognito.config).then(
//     store.dispatch, render).then(render);

// store.dispatch().then(render)
// render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// import * as serviceWorker from './serviceWorker';
// serviceWorker.unregister();
