import * as ReactGA from 'react-ga';
import { History } from 'history';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-80819523-1');
}

export function initialiseAnalytics(history: History) {
    history.listen(location => {
        const page = location.pathname; // + location.search;
        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(page);
        } else {
            console.info('in prod we would generate a Google Analytics pageview:', page);
        }
    });
}

export function recordEvent(category: string, action: string, label?: string, value?: number) {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({category, action, label, value});
    } else {
        console.info('Analytic event:', category, action, label, value);
    }
}
