import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { JobPosting } from '../../services/job-search-service';
import { SearchItemAction } from '../../actions/SearchItemAction';
// import { useEffect, useRef } from 'react';
// import { MouseEvent } from 'react';

const useStyles = makeStyles({
    header: {
        padding: 16,

        '& h1': {
            fontSize: 24,
            margin: 0
        },
        '& .org': {
            marginRight: 20
        },
        '& .adr i, & .adr .status, & .adr geo': {
            display: 'none'
        }
    },
    details: {
        padding: '0 16px 16px',

        '& .description': {
            margin: 0
        }
    }
}, {name: 'job-summary'});

// ga('send', 'event', 'JobSummary', 'Save', this.job.id, 'click/drag/etc');
// ga('send', 'event', 'JobSummary', 'ShowIgnore', this.job.id, method);
// ga('send', 'event', 'JobSummary', 'Ignore', this.job.id, method);
// ga('send', 'event', 'JobSummary', 'ClickJob', this.job.id);
// ga('send', 'event', 'JobSummary', 'ShowOnMap', this.job.id);
export interface JobSummaryAction {
    type: 'click-item-link' | 'show-on-map';
    payload: {
        listing: JobPosting;
    };
}

export type JobSummaryDispatch = (action: JobSummaryAction | SearchItemAction) => void;

// Adding `<script type="text/javascript" src="https://gdc.indeed.com/ads/apiresults.js" />` didn't work, so copied inline below
// @ts-ignore
function indeed_clk(a: HTMLAnchorElement, sig: string) {
    var hr = a.href;
    var si = hr.indexOf('&jsa=');
    if (si > 0) {
        return;
    }
    var jsh = `${hr}&jsa=${sig}`;
    if (jsh.indexOf('&inchal') === -1) {
        jsh += '&inchal=apiresults';
    }
    a.href = jsh;
}

interface JobSummaryState {
    job: JobPosting;
    // selected?: boolean;
    dispatch: JobSummaryDispatch;
}

export default function JobSummary({job, dispatch}: JobSummaryState) {
    const classes = useStyles();
    // const titleLink = React.useRef<HTMLAnchorElement>(null);
    // React.useEffect(() => {
    //     if (titleLink.current && job.onmousedown) {
    //         titleLink.current.setAttribute('onmousedown', job.onmousedown);
    //     }
    // }, [titleLink]);

    function onMouseDownJobLink(e: React.MouseEvent) {
        // eg: 'indeed_clk(this,'3182');'
        if (job.onmousedown) {
            (function() {
                // tslint:disable-next-line:no-eval
                eval(job.onmousedown!);
            }).call(e.target);
        }
    }
    // tslint:disable-next-line:no-eval
    // const mouseDownJob = job.onmousedown && eval(job.onmousedown);

    function onFocus() {
        dispatch({type: 'focus-item', payload: {id: job.id}});
    }

    function onClickJobLink() {
        dispatch({type: 'click-item-link', payload: {listing: job}});
    }

    function showWorkplaceOnMap(e: React.MouseEvent) {
        e.preventDefault();
        dispatch({type: 'show-on-map', payload: {listing: job}});
    }


    /* <div
            className={classes.root + ' ' + (selected ? 'selected-item' : '')}
            onMouseDown={onFocus}
        >
        <Paper

            elevation={selected ? 8 : job.sponsored ? 4 : 1}
        >*/
    return (
        <div itemScope itemType="http://schema.org/JobPosting">
            <div className={classes.header}>
                {/*<RatingDisplay tabindex="0" on-tap="showRatingsDlg()" value={job.rating}"></RatingDisplay>*/}
                <h1 itemProp="title">
                    <a
                        href={job.url}
                        target="detail"
                        itemProp="url"
                        onMouseDown={onMouseDownJobLink}
                        onClick={onClickJobLink}
                        onFocus={onFocus}
                    >
                        {job.title}
                    </a>
                </h1>
                {job.company.name && <span
                    className="org"
                    itemScope
                    itemProp="hiringOrganization name"
                    itemType="http://schema.org/Organization"
                    // onClick={showCompany}
                    // title="Company info pop-up, flag as agency"
                >
                    <span itemProp="name">
                        {job.company.name}
                    </span>
                </span>
                }
                    {/* <a class="control" href="#flag-as-agency?company={$companyId|id}" title="Flag this company as a recruitment agency">
                        	<img src="/icons/silk/group_error.png"/>
                        	<img src="/icons/silk/user_gray.png"/>
                        	<img src="/icons/silk/user_edit.png"/>
                        	<img src="/icons/silk/building_edit.png"/>
                          	<!- - <span class='ui-icon ui-icon-notice'></span> - ->
                        		</a>
                        	 	<div itemprop="jobLocation" itemscope itemtype="http://schema.org/Place">
                        <a class="adr" href="/company/{$companyId|id}/workplaces/{$workplaceId|id}">*/}
                <a
                    className="adr"
                    itemScope
                    itemProp="jobLocation"
                    itemType="http://schema.org/Place"
                    onClick={showWorkplaceOnMap}
                >
                    <span itemScope itemProp="address" itemType="http://schema.org/PostalAddress">
                        {job.address.street &&
                            <i className="street-address" itemProp="streetAddress">{job.address.street}</i>
                        }
                        <span className="locality" itemProp="addressLocality">{job.address.city}</span>,&nbsp;
                        <span className="region" itemProp="addressRegion">{job.address.state}</span>
                    </span>
                    {/*<i className="status">{job.address.status}</i>*/}
                </a>
                <time style={{display: 'none'}} itemProp="datePosted">{new Date(job.timestamp).toISOString()}</time>
                {/*<div style={{display: 'none'}} itemProp="employmentType">FULL_TIME, CONTRACTOR</div>*/}
            </div>
            <div className={classes.details}>
                {/*<!-- </div>
                       <!- - <span className="ui-icon ui-icon-search"></span> - ->
                       <a class="control" href="#edit-location" title="Can you provide a more precise location for this company?">
                       	<img src="/icons/silk/map_edit.png"/>
                       	<!- - <span class="ui-icon ui-icon-pencil"></span> - ->
                       </a> -->*/}
                {/*<div className="remuneration" itemScope itemType="http://schema.org/MonetaryAmount" itemProp="baseSalary">
                    <span style={{display: 'none'}} itemProp="currency">{job.package.currency.code}</span>
                    <span className="listedRange">{{job.package.range}}</span>
                    {job.package.amount &&
                        <div itemProp="value" itemType="http://schema.org/QuantitiveValue">
                            <!-- itemProp="minValue" itemProp="maxValue" itemProp="unitText" (HOUR, DAY, WEEK, MONTH, YEAR)
                            <meta content="{{job.package.currency.code}}"/>
                            <span className="currency" title="{{job.package.currency.code}}">{job.package.currency.symbol}</span>
                            <span itemprop="value" className="amount">{job.package.amount}</span>
                            <span class="frequency">{job.package.frequency}</span>
                        </div>
                        }
                    <span className="notes">{job.package.source}</span>
                </div>*/}
                <p itemProp="description" className="description" dangerouslySetInnerHTML={{__html: job.snippet}}/>
            </div>
            {/* maximised &&
                <iframe is="external-job-details" src="[[job.url]]"></iframe>
            */}
            {/*<CardActions>
                <Button class="btn-success" onClick="_tapSave">
                    <ThumbUpIcon />
                    Save
                </Button>
                <Button class="btn-danger" on-tap="_tapIgnore">
                    <ThumbDownIcon />
                    Ignore
                </Button>
            </CardActions> */}
        </div>
    );
}
