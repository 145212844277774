/**
 * When first loading the script, check to see if the user has just been logged in with an auth token
 *
 * - decodeURIComponent(sessionStorage.getItem('auth-state'))
 *   == 'https://localhost:3000/#?date=1500901495659'
 * - window.location.hash
 *                          == '#?date=1500901495659 &token='
 *                          + encodeURIComponent(JSON.stringify({token_type, access_token, refresh_token, expires_in})
 *
 */
export function extractAuthToken() {
    let loc = window.location;
    if (loc.hash) {
        let match = window.location.hash.match(/^(.*)(#.*)&token=(.+)/);
        if (match) {
            // window.location.hash = '';
            history.replaceState('', document.title, loc.pathname + loc.search);

            let authState = sessionStorage.getItem('auth-state');
            if (authState) {
                let expectedUrl = new URL(decodeURIComponent(authState));
                if (expectedUrl.pathname !== loc.pathname || expectedUrl.hash !== match[2]) {
                    console.error('Received token, but URL not as expected');
                    console.info(loc);
                    console.info(expectedUrl);
                    return;
                }

                sessionStorage.removeItem('auth-state');
            }

            let tokens = JSON.parse(decodeURIComponent(match[3]));
            localStorage.setItem('access_token', tokens.access_token);
            localStorage.setItem('refresh_token', tokens.refresh_token);
            localStorage.setItem('user', JSON.stringify(tokens.user));
            return tokens;
        }
    }

    const user = localStorage.getItem('user');

    return {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        user: user ? JSON.parse(user) : undefined
    };
}

export function removeAuthToken() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
}
