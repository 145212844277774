import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';

import './_app-bar.scss';

import AppNav from './AppNav';
import Dubbie from './Dubbie';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../theme';
const muiTheme = createMuiTheme(theme);

import createRoutes from '../routes/index';
import Footer from './Footer';
import { initialiseAnalytics } from '../services/analytics';
import { AuthAction, useAuthService } from '../services/AuthService';
import { AUTH_STATE, COARSE_LOCATION } from '../actions/action-types';
import { User } from '../store/User';
import { createAction } from '../actions/flux-standard-actions';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

const history = createBrowserHistory();
// const store = createStore(/*window['___INITIAL_STATE__']*/);

// this attempts to retrieve the user from local storage and establish
// a new session for them
// const state = store.getState();
// const guard = createGuard(store, '/forbidden');

// const store = {user:{}};
const routes = createRoutes();

// const showMe = new ShowMe(fbConfig, undefined, new AuthService(store));
// showMe.subscribe();

// import { User } from '../store/User';
// import { extractAuthToken } from '../services/token-parser';
// const { user, access_token } = extractAuthToken();

interface BotLayout {
  hostDiv?: HTMLDivElement | null;
  children?: React.ReactElement;
}

interface AppContext {
//   authService?: AuthService;
  user?: User;
  setBotLayout: (layout: BotLayout) => any; // React.Ref<HTMLDivElement>;
}
export const AppContext = React.createContext<AppContext>({} as AppContext);

initialiseAnalytics(history);
interface AppState {
  user?: User;
  location?: CoarseLocation;
}

interface CoarseLocation {
  ip: string;
  city: string;
  region: string;
  countryCode: string;
}

interface LocationAction {
  type: 'COARSE_LOCATION';
  payload: CoarseLocation;
}

const initialState: AppState = {
  // user
};
function reducer(state: AppState, action: AuthAction | LocationAction): AppState {
  console.info('----------------------APP reducer:', state, action);
  switch (action.type) {
  case AUTH_STATE:
    if (action.payload === state.user) {
      // console.info('user is actually the same');
      return state;
    }
    // console.info('AUTH_STATE action - user:', action.payload);
    return {...state, user: (action as AuthAction).payload};
  case COARSE_LOCATION:
    return {...state, location: (action as LocationAction).payload};
  default:
    return state;
  }
}

const App = () => {
  const [authState, authDispatch] = useAuthService(reducer, initialState);
  const defaultHost = useRef<HTMLDivElement | null>(null);
  // const botRef = useRef<HTMLDivElement | null>(null);
  // const [botHostDiv, setBotHostDiv] = useState<HTMLElement | null>(defaultHost.current);
  const [botLayout, _setBotLayout] = useState<BotLayout>({});

  // console.info('=============================== App', state);

  useEffect(() => {
    const locationStr = sessionStorage.getItem('location');
    if (locationStr) {
      return authDispatch(createAction(COARSE_LOCATION, JSON.parse(locationStr)));
    }

    fetch('https://us-central1-dubbie-bee.cloudfunctions.net/location').then(response => {
      response.json().then(json => {
        console.info('location:', json);
        sessionStorage.setItem('location', JSON.stringify(json));
        authDispatch(createAction(COARSE_LOCATION, json));
      });
    });
  }, []);

  const setBotLayout = function(layout: BotLayout) {
    _setBotLayout(layout);
    // return botRef;
  };
  // const setBotHost = function(hostDiv: HTMLDivElement | null, botChildren?: React.ReactElement) {
  //   setBotHostDiv(hostDiv); // || defaultHost.current);
  // };

  const dubbie = useMemo(() => {
    console.info('updating Dubbie, botLayout:', botLayout);
    // for AWS Lex NLP: userId={state.user && state.user.id}
    return (
        <Dubbie history={history}>
          {botLayout.children}
        </Dubbie>
    );
    // this gives history, location and match, but match is just "/". return <Route component={Dubbie} />;
  }, [history, botLayout.children]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <AppContext.Provider
          value={{
            // authService: authService
            user: authState.user,
            setBotLayout
          }}
      >
        <Router history={history}>
          <div id="content-wrap">
            <AppNav history={history} user={authState.user}/>

            <div id="content" >
              {routes}

              <div ref={defaultHost} className="bot-default-host"/>
              {false && <BotPortal bot={dubbie} botHost={botLayout.hostDiv || defaultHost.current}/>}
              {/*{dubbie}*/}
            </div>
          </div>
          <Footer/>
        </Router>
      </AppContext.Provider>
    </MuiThemeProvider>
  );
};

function BotPortal({bot, botHost}: {bot: ReactNode, botHost: HTMLElement | null}) {
  console.info('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!BotPortal(bot:', bot, ', botHost:', botHost);
  return createPortal(bot, botHost || document.body);
}

// App.whyDidYouRender = true;
// App.whyDidYouRender = {
//   logOnDifferentValues: true,
//   collapseGroups: true
// };
export default App;
