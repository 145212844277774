import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { History } from 'history';

// import FirebaseChannel from 'react-web-bot/src/channels/FirebaseChannel';
import WebBot, { BotResponse, WebBotState, WebBotAction } from 'react-web-bot/src/components/WebBot';
import { createDialogflowNLP } from 'react-web-bot/src/services/DialogflowNLP';
import DelegatingNLP from 'react-web-bot/src/services/DelegatingNLP';
import PatternBasedNlp from 'react-web-bot/src/services/PatternBasedNlp';
import { UserResponse } from 'react-web-bot/src/services/UserResponse';
import { DialogflowMessage, DialogflowTextMessage, DialogflowImageMessage,
    DialogflowCardMessage, DialogflowBasicCardMessage,
    DialogflowSuggestionsMessage, DialogflowLinkOutSuggestionsMessage,
    DialogflowListSelectMessage, DialogflowCarouselSelectMessage,
    DialogflowQuickReplyMessage, DialogflowSimpleResponsesMessage
} from 'react-web-bot/src/services/DialogflowTypes';
import VoiceLink from 'react-web-bot/src/components/VoiceLink';
import DialogflowImage from 'react-web-bot/src/components/dialogflow/DialogflowImage';
import DialogflowBasicCard from 'react-web-bot/src/components/dialogflow/DialogflowBasicCard';
import DialogflowCard from 'react-web-bot/src/components/dialogflow/DialogflowCard';
import DialogflowCarouselSelect from 'react-web-bot/src/components/dialogflow/DialogflowCarouselSelect';
import DialogflowLinkOutSuggestions from 'react-web-bot/src/components/dialogflow/DialogflowLinkOutSuggestions';
import DialogflowSimpleResponses from 'react-web-bot/src/components/dialogflow/DialogflowSimpleResponses';
import DialogflowListSelect from 'react-web-bot/src/components/dialogflow/DialogflowListSelect';
import DialogflowSuggestions from 'react-web-bot/src/components/dialogflow/DialogflowSuggestions';
import DialogflowQuickReplies from 'react-web-bot/src/components/dialogflow/DialogflowQuickReplies';
import { NlpService, NLPResponse, NoMatch, NlpResponseCallback, ContextData } from 'react-web-bot/src/services/NlpService';

import '../components/_web-bot.scss';
import { getRouteMatchForPath } from '../routes';
import { parseQueryString, toQueryString } from '../services/query-string';
import { welcomeForm, welcomePrompt } from '../prompts/welcome-prompt';
import { startPrompt } from '../prompts/start-prompt';
import { AppContext } from './App';
// import DubbieBeeAgent from '../agents/job-search-agent';
import { Agent, generateSampleUtterances } from '../services/agent-service';
import { getAuthService, googleScope } from '../services/AuthService';
import { signIn } from '../components/auth/SignInButton';
import JobSummary from '../components/job-search/JobSummary';
// import DubbieImg from '../images/dubbie.png'


export interface DubbieProps {
    history: History;
}

class LocalNLP implements NlpService {
    // constructor(private history: History) {}

    processUserResponse(userResponse: UserResponse, context: ContextData, callback: NlpResponseCallback) {
        console.info('Dubbie LocalNLP has userResponse:', userResponse, 'context:', context);

        // In-memory handling of basic user commands.
        // Set `response` to an NLPResponse (even empty) if handled.
        let path = context.path, // this.context.router.route.location.pathname,
            command = userResponse.value.toLocaleLowerCase();
            // state;

        if (userResponse.inputType === 'event') {
            if (userResponse.value === 'WEB_WELCOME_EVENT') {
            //     command = 'welcome';
                return callback(null, {intentName: 'Web Welcome'});
            } else if (userResponse.value === 'WEB_START_EVENT') {
                return callback(null, {
                    intentName: 'Web Start',
                    context
                });
            } else if (userResponse.value === 'WEB_JOBS_EVENT') {
                return callback(null, {
                    intentName: 'Web Jobs',
                    context
                });
            }
        } else {
            // clean up command
            command = command.replace(/^can (i|we)/, '');
            command = command.replace(/^i want (to)?/, '');
            command = command.replace(/[!?]+$/, '');
            command = command.trim();

            // convert to command
            if (path === '/') {
                if (['maybe later'].indexOf(command) >= 0) {
                    command = 'start';
                } else {
                    let match = command.match(/sign in with (.*)\b/);
                    if (match) {
                        return callback(null, {
                            intentName: 'Web Sign In',
                            slots: {
                                provider: match[1]
                            }
                        });
                    } else {
                        match = command.match(/link(?: my)?(?: \b(.*)\b)? account/);
                        if (match) {
                            command = 'account linking';
                            // state = {provider: match[1]};
                        }
                    }
                }
            } else if (path !== '/start' && ['restart', 'start over', 'start again'].indexOf(command) >= 0) {
                command = 'start';
            }
        }

        let nlpResponse: NLPResponse | null;
        switch (command) {
            // case 'welcome':
            //     response = {
            //         intentName: command
            //     };
            //     break;
            case 'start':
                // this.history.push('/start');
                nlpResponse = {
                    intentName: 'Web Start',
                    context
                };
                break;
            case 'account linking':
                // this.context.router.history.push('/account/linking', state);
                nlpResponse = {};
                break;
            default:
                // this.props.dispatch(createAction(USER_RESPONSE, userResponse));
                // defer to next delegate
                nlpResponse = null;
        }

        callback(null, nlpResponse);
    }
}

function createPatternNLP(agents: Agent[]) {
    let patternNlp = new PatternBasedNlp();

    for (const agent of agents) {
        for (const intent in agent.intents) {
            patternNlp.addIntent(intent, agent.intents[intent]);
        }
        if (agent.entities) {
            for (const entity in agent.entities) {
                patternNlp.addEntities(entity, agent.entities[entity]);
            }
        }
    }

    return patternNlp;
}

// add this.props.firebase
// @firebaseConnect()
// Map redux state to props
// @connect(mapStateToProps /*, mapDispatchToProps*/) //mergeProps, options
function Dubbie(props: React.PropsWithChildren<DubbieProps>) { // , ref: React.Ref<HTMLDivElement>) {
    console.info('Creating Dubbie, props...:', props);
    const app = useContext(AppContext);
    const [agents, setAgents] = useState<Agent[]>([]);

    function ensureAgentForPath(path: string) {
        console.info('ensureAgentForPath:', path);
        if (path === '/' || path === '/start') {
            Promise.all([
                import('../agents/dubbie-bee-agent'),
                import(/* webpackChunkName: "jobs-agent" */ '../agents/job-search-agent')
            ]).then(promisedAgents => {
                console.info('promisedAgents', promisedAgents);
                setAgents(promisedAgents.map(agent => (agent.default as Agent)));
            });
        } else if (path.match(/^\/jobs\b/)) {
            console.info('on jobs page', agents);
            let i = agents.length;
            while (i-- > 0) {
                if (agents[i].name === 'Job Search') { return; }
            }
            console.info('loading job agent...');
            import(/* webpackChunkName: "jobs-agent" */ '../agents/job-search-agent').then(agent => {
                console.info('loaded jobs agent', agent.default);
                setAgents(agents.concat(agent.default as Agent));
            }).catch(err => console.error);
        }
    }

    function ensurePathForIntent(intentName: string, slots?: {[key: string]: string}, context?: ContextData) {
        console.info('        ensurePathForIntent:', intentName, agents);
        console.info('          slots:', slots, 'context:', context);
        // debugger;
        for (const agent of agents) {
            for (const intent in agent.intents) {
                if (intent === intentName) {
                    const params = agent.intents[intent].searchParams ? agent.intents[intent].searchParams!(slots, context) : slots;
                    const search = toQueryString(params);
                    console.info('found matching intent:', props.history.location.pathname, '->', agent.intents[intent], 'search:', search);
                    if (props.history.location.pathname !== agent.intents[intent].path) {
                        props.history.push({
                            pathname: agent.intents[intent].path,
                            search
                        });
                    }
                    return;
                }
            }
        }
    }

    function reducer(state: WebBotState, action: WebBotAction) {
        console.info('Dubbie reducer( state:', state, 'action:', action.type, action.payload);
        switch (action.type) {
            case 'INITIALISE':
                /*state = {
                    // ...state, // default avatarImage and loading prompt
                    avatarImage: action.payload!.pathname === '/' ? '/images/dubbie.png' : '/images/app-icon-32.png',
                    // prompts: ['Hello...'],
                    form: undefined,
                };

                const match = getRouteMatchForPath(props.history.location.pathname);
                if (match) {
                    state.userResponse = {
                        inputType: 'event',
                        value: 'WEB' + match.path.replace(/\W+/g, '_').toUpperCase() + '_EVENT',
                        parameters: match.params
                    };
                    ensureAgentForPath(action.payload!.pathname);
                // } else {
                //     // TODO: can we do better than this? ...Through the Agent configuration?
                //     state.prompts = ['Hi! How can I help you?'];
                }

                return state;*/
            case 'LOCATION_CHANGE':
                state = {
                    ...state,
                    avatarImage: action.payload!.pathname === '/' ? '/images/dubbie.png' : '/images/app-icon-32.png',
                    // prompts: ['Route changed....'],
                    form: undefined,
                };

                const match = getRouteMatchForPath(props.history.location.pathname);
                if (match) {
                    const eventName = 'WEB' + (match.path === '/' ? '_WELCOME' :  match.path.replace(/\W+/g, '_').toUpperCase()) + '_EVENT';
                    state.userResponse = {
                        inputType: 'event',
                        value: eventName,
                        parameters: {...match.params, ...parseQueryString(props.history.location.search)},
                        // nlpContext
                    };
                    ensureAgentForPath(action.payload!.pathname);
                }
                return state;
            default:
                return state;
        }
    }

    // if (false) {
    // const [state, dispatch] = useWebBot(props.history, dubbieReducer); // {agents: props.agents});
    // }

    const [nlpService, setNlpService] = useState<NlpService>(() => {
        console.info('... DubbieBee creating new DelegatingNLP');
        const nlp = new DelegatingNLP();
        nlp.pushDelegate(new LocalNLP()); // props.history));
        if (false && agents) {
            nlp.pushDelegate(createPatternNLP(agents!));
        }

        return nlp;
    });

    // Once authenticated with a token, adds the Dialogflow service to `nlpService`.
    // Cancels the auto refresh when Dubbie is destroyed.
    useEffect(() => {
        console.info('---- DubbieBee calling createDialogflowNLP');
        // Can't provide accurate location (countryCode is always 'US' and city/region '?') when we go through Firebase hosting
        // return createDialogflowNLP('https://dubbiebee.com/api/bot/dialogflow/token',
        return createDialogflowNLP('https://us-central1-dubbie-bee.cloudfunctions.net/dialogflow-webhook/token',
                                    dialogFlow => {
            setNlpService((oldNlp: DelegatingNLP) => {
                const newNlp = new DelegatingNLP();
                newNlp.delegates = oldNlp.delegates.concat(dialogFlow);
                return newNlp;
            });
        });
    }, []);

    // // Listen for page location changes, and unsubscribe on cleanup
    // useEffect(() => {
    //     return props.history.listen((location: Location) => {
    //         console.info('Dubbie detected location change:', location);
    //     });
    // }, []);


    function onNlpResponse(err: null, nlpResponse: NLPResponse): BotResponse;
    function onNlpResponse(err: Error | NoMatch, nlpResponse?: NLPResponse): BotResponse;
    function onNlpResponse(err?: Error | NoMatch | null, nlpResponse?: NLPResponse): BotResponse {
        if (err) {
            // console.error(err);
            if ((err as NoMatch).userResponse && (err as NoMatch).userResponse.inputType === 'event') {
                return {
                    // TODO: can we do better than this? ...Through the Agent configuration?
                    prompts: ['Hi! How can I help you?']
                };
            }
            if ((err as NoMatch).nlpResponse) {
                nlpResponse = (err as NoMatch).nlpResponse;
            } else {
                return {
                    prompts: ['Sorry, something went wrong!']
                };
            }
        }
        console.info('NLP response received by Dubbie:', nlpResponse, nlpResponse!.context);

        const botResponse: BotResponse = {
            nlpContext: nlpResponse!.context,
            prompts: []
        };

        const previousPath = nlpResponse!.context && nlpResponse!.context!.previousPath; // props.history.location.pathname;
        ensurePathForIntent(nlpResponse!.intentName!, nlpResponse!.slots, nlpResponse!.context);

        switch (nlpResponse!.intentName) {
            case 'Web Welcome':
                botResponse.prompts!.push(welcomePrompt);
                botResponse.form = welcomeForm;
                break;
            case 'Web Sign In':
                signIn(nlpResponse!.slots!.provider,
                        {
                            'google': googleScope
                        }[nlpResponse!.slots!.provider],
                        null as any,
                        getAuthService().beforeSignIn);
                break;
            case 'Web Start':
                botResponse.prompts!.push(startPrompt(app.user!, previousPath));
                botResponse.form = (
                    <div>
                        {generateSampleUtterances(agents, 2, ['Dubbie Bee']).map((item, i) => {
                            return (
                                <div key={i}>
                                    <VoiceLink href={item.href}>
                                        {item.text}
                                    </VoiceLink>
                                </div>
                            );
                        })}
                    </div>);
                break;
            case 'Web Jobs':
                botResponse.prompts!.push('What do you think of this job?');
                if (false) {
                    botResponse.prompts!.push((
                        <JobSummary
                            job={{
                                'address': {
                                    'city': 'Melbourne',
                                    'country': 'AU',
                                    'state': 'VIC'
                                },
                                'company': {
                                    'name': 'Victorian Government'
                                },
                                'expired': false,
                                'id': 'indeed-0a24a4d6db967d2e',
                                'location': {
                                    'latitude': -37.8127329,
                                    'longitude': 144.9704036,
                                    'status': 2
                                },
                                'onmousedown': 'indeed_clk(this,\'5743\');',
                                'rating': 9,
                                'snippet': 'Range 1 classroom <b>teachers</b> are skilled <b>teachers</b> who operate under general direction within clear ' +
                                    'guidelines following established work practices and documented...',
                                'sponsored': false,
                                'timestamp': 1564529882000,
                                'title': 'Graduate Teacher Program - Upper Primary Part Time 0.6',
                                // tslint:disable-next-line:max-line-length
                                'url': 'http://au.indeed.com/viewjob?jk=0a24a4d6db967d2e&qd=IorWCXlm6ODPS-5kMVRXJyTiZqlSO9euxyvRsROFFZ9_mO_rK0vFz5XnvhA2XZKrqlWity4H3sIsXCp5TLWclR6nojn63AzuMLy1372sZMY&indpubnum=6287716287940388&atk=1dh32uo3go1n4800'
                            }}
                            dispatch={() => console.info('TODO: WebJobs Dispatch')}
                        />
                    ));
                }
                break;
            default:
                if (nlpResponse!.message) {
                    (nlpResponse!.message as DialogflowMessage[]).forEach(message => {
                        if ((message as DialogflowTextMessage).text) {
                            botResponse.prompts!.push((message as DialogflowTextMessage).text.text.join('<br>'));
                        } else if ((message as DialogflowImageMessage).image) {
                            botResponse.prompts!.push(<DialogflowImage {...(message as DialogflowImageMessage).image}/>);
                        } else if ((message as DialogflowCardMessage).card) {
                            botResponse.prompts!.push(<DialogflowCard {...(message as DialogflowCardMessage).card}/>);
                        } else if ((message as DialogflowBasicCardMessage).basicCard) {
                            botResponse.prompts!.push(<DialogflowBasicCard {...(message as DialogflowBasicCardMessage).basicCard}/>);
                        } else if ((message as DialogflowSuggestionsMessage).suggestions) {
                            botResponse.form = <DialogflowSuggestions {...(message as DialogflowSuggestionsMessage).suggestions}/>;
                        } else if ((message as DialogflowLinkOutSuggestionsMessage).linkOutSuggestions) {
                            botResponse.prompts!.push(<DialogflowLinkOutSuggestions {...(message as DialogflowLinkOutSuggestionsMessage).linkOutSuggestions}/>);
                        } else if ((message as DialogflowListSelectMessage).listSelect) {
                            botResponse.prompts!.push(<DialogflowListSelect {...(message as DialogflowListSelectMessage).listSelect}/>);
                        } else if ((message as DialogflowCarouselSelectMessage).carouselSelect) {
                            botResponse.prompts!.push(<DialogflowCarouselSelect {...(message as DialogflowCarouselSelectMessage).carouselSelect}/>);
                        } else if ((message as DialogflowQuickReplyMessage).quickReplies) {
                            botResponse.prompts!.push(<DialogflowQuickReplies {...(message as DialogflowQuickReplyMessage).quickReplies}/>);
                        } else if ((message as DialogflowSimpleResponsesMessage).simpleResponses) {
                            botResponse.prompts!.push(<DialogflowSimpleResponses {...(message as DialogflowSimpleResponsesMessage).simpleResponses}/>);
                            // } else if ((message as DialogflowCustomMessage).payload) {
                        }
                    });
                }
        }

        // dispatch({type: 'NLP_RESPONSE', payload: botResponse});
        return botResponse;
    }

    // {/*onUserResponse={this.props.onUserResponse} */}
    // onNlpResponse={onActionCallback(this.props.dispatch, NLP_RESPONSE)}
    // /*<FirebaseChannel/>*/
    return (
        <WebBot
            // avatarImage={state.avatarImage}
            config={{continuous: true}}
            service={nlpService}
            onNlpResponse={onNlpResponse}
            // prompts={state.prompts}
            // form={state.form}
            history={props.history}
            reducer={reducer}
        >
            {props.children}
        </WebBot>
    );
}

Dubbie.whyDidYouRender = true;
Dubbie.whyDidYouRender = {
    logOnDifferentValues: true,
    collapseGroups: true
};

// export default forwardRef<HTMLDivElement, DubbieProps>(Dubbie);
export default Dubbie;
