// <reference path="../../scripts/import"/>
import * as React from 'react';
import { Suspense, lazy } from 'react';
import { matchPath, Route, Switch } from 'react-router-dom';
// import {RouteComponentProps} from 'react-router';
// import { AppState } from '../store/state';
// import LazyRoute from './LazyRoute';
// import asyncComponent from '../components/AsyncComponent';
import Home from './Home';
import Start from './Start';
// const Start = asyncComponent(() => import('./Start') as any);
// const Start = lazy(() => import('./Start'));
// import Jobs from './jobs/index';
const Jobs = lazy(() => import(/* webpackChunkName: "jobs" */ './jobs/index'));
const JobPage = lazy(() => import(/* webpackChunkName: "jobs" */ './jobs/JobPage'));
const TalentPage = lazy(() => import(/* webpackChunkName: "talent" */ './talent/TalentPage'));
const AccountLinking = lazy(() => import(/* webpackChunkName: "account" */ './account/AccountLinking'));
const Authorize = lazy(() => import(/* webpackChunkName: "account" */ './account/Authorize'));
const ContactUs = lazy(() => import(/* webpackChunkName: "contact" */ './ContactUs'));
const Privacy = lazy(() => import(/* webpackChunkName: "privacy" */ './info/privacy'));
const TermsOfService = lazy(() => import(/* webpackChunkName: "privacy" */ './info/terms-of-service'));
// import RealEstateRentals from './real-estate/rentals';
import Loading from '../components/Loading';
import NotFound from './NotFound';
// import NotFound from './NotFound';

/*
const Components = {
    Jobs,
    JobPage
};
export const AsyncComponent = (props: {componentName: string}) => {
    console.info(props);
    const { componentName } = props;
    const Component = Components[componentName];
    return <Component {...props} />;
};*/

const routes = [
    { path: '/', exact: true, component: Home },
    { path: '/start', component: Start },

    { path: '/account/authorize', component: Authorize },
    { path: '/account/linking', component: AccountLinking },

    { path: '/jobs', exact: true, component: Jobs },
    { path: '/jobs/search-profile/:profileName?', component: Jobs },
    { path: '/jobs/id/:jobId', component: JobPage },

    { path: '/talent', component: TalentPage },

    { path: '/contact-us', component: ContactUs },
    { path: '/info/privacy', component: Privacy },
    { path: '/info/terms-of-service', component: TermsOfService },
    // { path: '/real-estate/rent', component: RealEstateRentals },
];

// @ts-ignore
export function getRouteMatchForPath(path: string) {
    let i = routes.length;
    while (i-- !== 0) {
        const match = matchPath(path, routes[i]);
        if (match) {
            return match;
        }
    }
}

export default function() {
    return (
        <Suspense fallback={<Loading/>}>
            <Switch>
                {routes.map(({path, exact, component}, i) => <Route key={i} path={path} exact={!!exact} component={component}/>)}
                <Route component={NotFound}/>
            </Switch>
        </Suspense>
    );
}
