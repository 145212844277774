/** Based on https://github.com/anthonyjgrove/react-google-login */
import * as React from 'react';
// import {User} from '../../store/User';
// import loadScript from 'showme-client/src/load-script';
import SignInButton, { SignInButtonClickHandler } from './SignInButton';
// import {createAction} from '../../actions/flux-standard-actions';
// import {AUTH_STATE} from '../../actions/action-types';
// import {googleClientId} from '../../config';

export interface GoogleSignInProps {
    // onSuccess: (user: User) => void;
    // onFailure: (error: Error) => void;
    label?: string;
    /** white or blue background */
    dark?: boolean;
    // clientId?: string;
    // onRequest: ();
    // buttonText?: string;
    // /** Allows for offline `access_token` retrieval during the signin process. */
    // offline?: boolean;
    // state?: string;
    scope?: string;
    // className?: string;
    // cookiePolicy?: string;
    // loginHint?: string;
    // hostedDomain?: string;
    // children?: JSX.Element;
    // style?: object;
    // disabledStyle?: object;
    // /**
    //  * Adds 'profile', 'email' and 'openid' to the requested scopes.
    //  * @default true
    //  */
    //  fetchBasicProfile?: boolean;
    // prompt?: string;
    // tag?: string;
    // autoLoad?: boolean;
    // disabled?: boolean;
    // discoveryDocs?: any[];
    // responseType?: string;
    /** @default 'popup' */
    uxMode?: 'popup' | 'redirect';
    /** If uxMode = 'redirect' default is current URL, stripped of query params */
    redirectUri?: string;
    isSignedIn?: boolean;
    onClick?: SignInButtonClickHandler;
}
// type GoogleSignInPropsWithDispatch = GoogleSignInProps & {dispatch: Dispatch<any>};

export interface GoogleSignInState {
    disabled: boolean;
}

export default function GoogleSignIn(props: GoogleSignInProps) {
    // const [disabled, setDisabled] = useState(true);

    // as per Google's recommendation
    // https://developers.google.com/identity/branding-guidelines
    // logo: 18px, 24px between logo and text, 8px padding
    // marginLeft: '1px', padding: '11px 10px 10px'
    /*let style = {
        backgroundColor: 'white',
        borderRadius: '2px',
        // padding: '9px 8px 8px',
        top: '1px',
        left: '1px'
    };*/

    // compatible with Facebook:
    // <span {marginLeft: '8px', marginRight: '5px', padding: '5px 5px 5px', borderRadius: '2px'
    // style = {
    //     backgroundColor: 'white',
    //     marginLeft: '8px',
    //     marginRight: '5px',
    //     borderRadius: '2px',
    //     padding: '5px 5px 5px'
    // };

    // style={style}

    return (
        // onClick={this.signIn}
        // state={this.props.state}
        <SignInButton provider="Google" label={props.label} scope={props.scope} dark={true || props.dark} onClick={props.onClick}>
            <span className="sign-in-button__icon">
                <svg style={{verticalAlign: 'text-bottom'}} width="18px" height="18px" viewBox="0 0 48 48">
                    <g>
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
                        <path fill="none" d="M0 0h48v48H0z"/>
                    </g>
                </svg>
            </span>
        </SignInButton>
    );
}
