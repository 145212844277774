import * as React from 'react';
import Button from '@material-ui/core/Button';
// import {TouchTapEventHandler} from 'material-ui';
import './_sign-in-button.scss';

export type SignInButtonClickHandler = (event: React.MouseEvent<Element>, state: string) => string;  // React.MouseEventHandler<{}>;

export interface SignInButtonProps {
    /** 'Google', 'Facebook' etc */
    provider: string;
    // clientId?: string;

    // state?: string;
    /** scope/permissions being requested from the provider */
    scope?: string;

    // icon?: string | JSX.Element;
    /** Base URL eg: https://www.facebook.com/v2.9/dialog/oauth */
    // href?: string;

    /** 'Login with Google' etc, 'Log Out'. Defaults to provider */
    label?: string;
    dark?: boolean;
    // labelColor?: string;
    buttonStyle?: any;
    overlayStyle?: any;
    // backgroundColor?: string;
    // onTouchTap?: TouchTapEventHandler;
    onClick?: SignInButtonClickHandler;
    children?: JSX.Element;
}

export function signIn(provider: string, scope: string | undefined, e: React.MouseEvent, onClick?: (e: React.MouseEvent, state: string) => string) {
    // we could call firebase.auth().linkWithRedirect(new firebase.auth.GoogleAuthProvider()) etc
    // but Firebase does not support Slack or LinkedIn
    let state = window.location.href + (window.location.href.indexOf('#?') < 0 ? '#?nonce=' : '&nonce=') + Date.now();
    if (onClick) {
        state = onClick(e, state);
    }
    state = encodeURIComponent(state);
    sessionStorage.setItem('auth-state', state);

    let href = 'https://dubbiebee.com/api/oauth?provider=' + provider + '&state=' + state;
    if (scope) {
        href += '&scope=' + scope;
    }

    window.location.href = href;
}

export default function SignInButton({provider, label, /*href, clientId,state,*/ scope, dark, onClick, children, ...other}: SignInButtonProps) {
    function _onClick(e: React.MouseEvent) {
        signIn(provider.toLowerCase(), scope, e, onClick);
    }

    /*if (!clientId) {
        clientId = (document.querySelector('meta[name="' + prov + '-signin-client_id"]') as HTMLMetaElement).content;
    }

    if (href) {
        href += '?response_type=code&client_id=' + clientId +
                '&redirect_uri=' + encodeURIComponent(redirectUri) +
                '&state=' + encodeURIComponent(state);

        if (scope) {
            href += '&scope=' + scope;
        }

        (other as any).href = href;
    }*/

    /*buttonStyle={{
        display: 'block',
            textAlign: 'left',
            minWidth: '130px',
    ...props.buttonStyle
    }}
    labelStyle={{
                textTransform: null,
                fontSize: '16px',
                paddingLeft: '14px'
            }}
    */

    let className = 'sign-in-button sign-in-button__' + provider;
    if (dark) {
        className += ' sign-in-button__dark';
    }

    return (
        <Button
            {...other}
            onClick={_onClick}
            className={className}
            variant="outlined"
        >
            {children} {label || provider}
        </Button>
    );
}
