// import { white, darkBlack, fullBlack, grey100, grey300, grey400, grey500 } from '@material-ui/core/styles/colors';
// import { fade } from '@material-ui/core/styles/colorManipulator';
// import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { brown } from '@material-ui/core/colors';

/*const darkBlack = 'rgba(0, 0, 0, 0.87)';
const fullBlack = 'rgba(0, 0, 0, 1)';
const white = '#ffffff';
const grey100 = '#f5f5f5';
const grey300 = '#e0e0e0';

const grey500 = '#9e9e9e';

const deepBrown = '#3E2723';



*/
// const honeyYellow = '#FFC107';
export const paleAmber = '#ffc838';
const honeyAmber = '#feba00';
// const hiveOrange = '#ff8a01';
const grey400 = '#bdbdbd';
export const lightTint = 'rgba(0, 0, 0, 0.2)';
export const midTint = 'rgba(0, 0, 0, 0.4)';
export const darkTint = 'rgba(0, 0, 0, 0.54)';
export const lightBrown = '#795548';
// export const deepBrown = '#3E2723';
export const darkBrown = '#372828';

export const body2Size = '0.875rem';

export default {
    // typography: {
    //     useNextVariants: true
    // },
    overrides: {
        /*h1: {
            fontFamily: ['comfortaabold', 'Roboto', 'Noto', 'sans-serif']
        }*/
    },
    palette: {
        primary: {
            dark: grey400,  // or 3E2723?
            light: 'white', // '#3E2723?',
            main: honeyAmber,
            contrastText: darkBrown,
        },
        secondary: brown,
        /*primary: {

        },

        text: {

        }*/
        /*
        accent1Color: '#795548',
        accent2Color: grey100,
        accent3Color: grey500,
        textColor: deepBrown,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: grey300,
        disabledColor: fade(darkBlack, 0.3),
        // pickerHeaderColor: cyan500,
        // clockCircleColor: fade(darkBlack, 0.07),
        shadowColor: fullBlack,*/
    }
    /*appBar: {
        textColor: darkBrown,
        color: honeyAmber
    }*/
} as ThemeOptions;
