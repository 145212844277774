import * as React from 'react';
import { NavLink } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core';
// import createStyles from '@material-ui/core/styles/createStyles';

// const useStyles = makeStyles(() => {
//    createStyles({
//        footer: {
//            // '@media (max-width: 500px)': {
//        }
//    });
// });


export default function Footer() {
    // const classes = useStyles();
    // className={classes.footer}

    // TODO: for larger screens, can we get away with a single-line footer?
    return (
        <div id="footer">
            <small>
                <ul>
                    <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                    <li><NavLink to="/info/privacy">Privacy</NavLink></li>
                    <li><NavLink to="/info/terms-of-service">Terms</NavLink></li>
                </ul>
                <div>© 2019 <a href="//unboxed.software">Unbo<span className="unboxedX rotated">x</span>ed Software</a></div>
            </small>
        </div>
    );
}
