// import {NLPService, SessionAttributes, NLPResponse, Slots, NlpResponseCallback} from 'react-web-bot/src/services/NLPService';
// import {UserResponse} from 'react-web-bot/src/services/UserResponse';

import { ContextData, Slots } from 'react-web-bot/src/services/NlpService';
import { EntityValues } from '../store/state';

const patternsMatch = /@(?:([^:]+):)?(\w+)\b/g;

export interface Agent {
    /** displayed to users */
    name: string;
    description?: string;
    logo?: string;
    options?: {
        // Lex config
        botName: string;
        botAlias: string;
        userId: string;
    } | {
        accessToken?: string; // used to select agent in api.ai
    };
    intents: {[name: string]: {
        /** Used to route to an appropriate page on receiving an NLP response */
        path: string;
        /**
         * Action phrases - https://developers.google.com/actions/discovery
         * Used by the AgentService to generate example utterances, and also by the LocalNLP service.
         * Can include 0 or many `{EntityName:slotName}` place holders, or just the `{slotName}`.
         * example: 'jobs for a {CareerLevel:careerLevel} {JobTitle:jobTitle}'
         */
        patterns: string[];
        /** A selection of prompts to play for each required slot */
        requiredSlots?: {[name: string]: string[]};
        searchParams?: (slots?: Slots, context?: ContextData) => {[name: string]: string},
    }};
    entities?: {[entityName: string]: EntityValues};
}

/**
 * @param agents
 * @param qty
 * @returns {Array<{text: string, href: string}>} eg: {text: 'jobs for a senior developer', href: '/jobs?careerLevel=senior&jobTitle=developer'}
 */
export function generateSampleUtterances(agents: Agent[], qty: number = 2, exclude?: string[]): Array<{text: string, href: string}> {
    const utterances: Array<{text: string, href: string}> = [];
    console.info('generateSampleUtterances', agents);

    // if there are more agents than qty, select `qty` of them randomly
    // otherwise use the agents more than once as required
    randomIndices(agents, qty, index => {
        // select a random pattern and substitute random entities if required
        let agent = agents[index];

        console.info('+++', agent);
        console.info('+++++++++++++++++++++', agent.name, 'exclude:', exclude && exclude.indexOf(agent.name));
        if (exclude && exclude.indexOf(agent.name) >= 0) {
            return -1;
        }
        let intent = randomItem(agent.intents),
            pattern = randomElement<string>(intent.patterns),
            text = pattern,
            href: string = intent.path,
            match: RegExpExecArray | null,
            qDelim = '?',
            value;

        while ((match = patternsMatch.exec(pattern)) !== null) {
            if (agent.entities && match[1]) {
                let entity = agent.entities[match[1]],
                    key = randomElement(Object.keys(entity));

                value = randomElement(entity[key]);
                href += qDelim + match[2] + '=' + encodeURIComponent(value);
            } else {
                value = '...';
                href += qDelim + 'promptFor=' + match[2];
            }

            text = text.replace(match[0], value);
            qDelim = '&';
        }

        return qty - utterances.push({text: text.replace(/a ([aeiou])/g, 'an $1'), href});
    });

    return utterances;
}

/*export function generateNlpService(agents: Agent[]): NLPService {
    // const regexps: RegExp[] = [],
    //     slots: Slots[] = [];
    let a = agents.length,
        match: RegExpExecArray | null;

    // for all agents
    while (a-- !== 0) {
        let agent = agents[a],
            substitutions: {[original: string]: Array<{value: string, regex: string}>} = {};

        // and all intents of each agent
        for (let intentName in agent.intents) {
            let intent = agent.intents[intentName],
                p = intent.patterns.length;

            // and all patterns of each intent
            while (p-- !== 0) {
                let pattern = intent.patterns[p];

                // substitute entity aliases into the regex
                while ((match = patternsMatch.exec(pattern)) !== null) {
                    const original = match[0];
                    // have we cached the substitution?
                    let subList = substitutions[original];
                    if (subList === undefined) {
                        // ...no - do it now
                        subList = [];

                        if (agent.entities && match[1]) {
                            let entity = agent.entities[match[1]];

                            for (let key in entity) {
                                let aliases = entity[key];
                                subList.push({value: key, regex: '(' + aliases.join('|') + ')'});
                            }

                            // regex.replace(match[0], );
                        }
                    }
                }


                          /!*

                        let reStr = pattern;

                        while ((match = patternsMatch.exec(pattern)) !== null) {
                            let entity = agent.entities[match[1]],
                                slotName = match[2];



                            e = entity;


                            key = randomElement(Object.keys(entity)),
                                value = randomElement(entity[key]);

                            text = text.replace(match[0], value);
                            href += qDelim + match[2] + '=' + encodeURIComponent(value);
                            qDelim = '&';
                        }
                    }*!/

                }
        }
    }

    console.info(regexps);

    return {
        processUserResponse(userResponse: UserResponse, context: SessionAttributes | undefined, callback: NlpResponseCallback) {
            let response: NLPResponse;

            for (matchers) {
                let match = matcher.match(matchers[i], userResponse.value);
                if (match) {
                    response = {
                        intentName: matcher.intentName,
                        slots: match.slots,
                        sessionAttributes: context
                    };
                    break;
                }
            }

            callback(null, response);
        }
    };
}*/

function randomElement<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
}

function randomKey(obj: {}): string {
    const keys = Object.keys(obj);
    return keys[Math.floor(Math.random() * keys.length)];
}

function randomItem(obj: {}): any {
    return obj[randomKey(obj)];
}

/**
 * The caller may ask for more indices than there are elements in the array.
 * The function will return repeated indices, but not clustered together.
 *
 * @param {Array} array
 * @param {number} qty
 * @param callback - optional callback which returns the number of indices still required, or -1 if no change
 * @returns {number[]} qty evenly and regularly distributed indices of the given array
 */
export function randomIndices(array: any[], qty: number, callback?: (index: number) => number) {
    let indices: Array<number | undefined> = [];
    const length = array.length;

    if (length) {
        let i = 0,
            offset = 0;

        while (true) {
            let limit = Math.min(qty, offset + length),
                r = Math.floor(Math.random() * length),
                rOffset = r + offset;

            while (true) {
                if (rOffset < qty && indices[rOffset] === undefined) {
                    indices[rOffset] = i - offset;

                    if (callback) {
                        const result = callback(r);
                        // console.info('.....result:', result);
                        if (result === 0) {
                            return indices;
                        } else if (result < 0) {
                            indices[rOffset] = undefined;
                            break;
                        }
                    }
                    if (i++ > qty) {
                        return indices;
                    }
                    // if ((callback && !callback(r)) || ++i >= qty) {
                    //     return indices;
                    // }
                    if (i % length === 0) {
                        offset += length;
                    }
                    break;
                }
                if (++rOffset >= limit) {
                    rOffset = offset;
                }
            }
        }
    }

    return indices;
}