
export function parseQueryString(qs: string, names?: string[]): {[name: string]: string} {
    let match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g;
    qs = qs.substr(1);

    let params = {};
    while (match = search.exec(qs)) {
        let name = match[1];
        if (!names || names.indexOf(name) >= 0) {
            params[name] = decodeURIComponent(match[2].replace(pl, ' '));
        }
    }

    return params;
}

export function toQueryString(params?: {[key: string]: number | string | boolean}) {
    let query = undefined;

    if (params) {
        for (const key in params) {
            const value = params[key];
            if (value !== '') {
                if (!query) {
                    query = '?';
                } else {
                    query += '&';
                }
                query += key + '=' + encodeURIComponent(params[key]);
            }
        }
    }

    return query;
}
